<template>
    <div>
        <div v-if="!isLoading" class="row">
            <div class="col-md-12 mb-4">
                <div class="col-md-3 col-lg-4 col-xxl-2">
                    <div class="page-length me-3">
                        <select @change="changeConcern" v-model="query.concern_id" class="form-select">
                            <option value="0">ALL Concern</option>
                            <option v-for="(item, index) in concernList" :key="index" :value="item.id">{{
                                item.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'tenderList' }">
                    <IconBox :backgroundColor="'#D80073'" :total="`${total.tender}`"></IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'contact_award' }">
                    <IconBox :total="`${total.contact_award}`" :title="'Contract awards'"
                        :icon="`${require('@/assets/img/icon/contact-award.png')}`" :backgroundColor="'#F9920F'">
                    </IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'contact_award' }">
                    <IconBox :total="`${parseFloat(total.contact_award_amount / 1000).toFixed(2)} k`"
                        :title="'Total Contract awarded Amount'" :icon="icon_tk" :backgroundColor="'#6A54DD'">
                    </IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'tenderList', query: { tender_type: 'Local' } }">
                    <IconBox :total="`${total.local_tender}`" :title="'Local Tender'" :icon="icon_local_tender"
                        :backgroundColor="'#1B87AF'">
                    </IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'tenderList', query: { tender_type: 'International' } }">
                    <IconBox :total="`${total.international_tender}`" :title="'International Tend.'"
                        :icon="icon_international_tend" :backgroundColor="'#CC4B4C'"></IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <IconBox :total="`${total.open_tender}`" :title="'Open Tender'" :icon="icon_open_tends"
                    :backgroundColor="'#1D38C4'">
                </IconBox>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <IconBox :total="`${total.close_tender}`" :title="'Close Tender'" :icon="icon_closed_tender"
                    :backgroundColor="'#00A138'">
                </IconBox>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'approval_security_receival' }">
                    <IconBox :total="`${parseFloat((total.total_security_deposite / 1000).toFixed(2))}K`"
                        :title="'Total Security Deposit'" :icon="icon_security_deposit" :backgroundColor="'#FF3A29'">
                    </IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'approval_security_receival' }">
                    <IconBox :total="`${parseFloat((total.total_receive_security_deposite / 1000)).toFixed(2)}K`"
                        :title="'Total Received Security Deposit'" :icon="icon_security_deposit"
                        :backgroundColor="'#FF3A29'"></IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'approval_security_receival' }">
                    <IconBox :total="`${parseFloat((total.total_unpaid_security_deposite / 1000)).toFixed(2)}K`"
                        :title="'Total Receival Security Deposit'" :icon="icon_security_deposit"
                        :backgroundColor="'#FF3A29'"></IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <router-link :to="{ name: 'performance_security_receival' }">
                    <IconBox :total="`${parseFloat((total.performance_security_amount / 1000)).toFixed(2)}K`"
                        :title="'performance Security'" :icon="icon_performance_security" :backgroundColor="'#9254DC'">
                    </IconBox>
                </router-link>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <IconBox :total="`${parseFloat((total.performance_security_receive_amount / 1000)).toFixed(2)}K`"
                    :title="'performance Security return'" :icon="icon_performance_security_return"
                    :backgroundColor="'#02A0FC'"></IconBox>
            </div>
            <div class="col-sm-6  col-md-6 col-xl-4 col-xxl-3 mb-4">
                <IconBox :total="`${parseFloat((total.performance_security_receiveable / 1000)).toFixed(2)}K`"
                    :title="'performance Security Receiveable'" :icon="icon_performance_security_return"
                    :backgroundColor="'green'"></IconBox>
            </div>
        </div>
        <!-- spinner loading -->
        <div v-else class="text-center mt-5 mb-5">
            <div class="spinner-grow  text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="chart-notification-area">
            <div class="row">
                <div v-if="notifications != null" class="col-md-6">
                    <div class="notification-area border  px-0 pt-3 pb-3">
                        <h4 class="px-2">Notifications </h4>
                        <hr>
                        <div class="notification-item">
                            <div class="upcoming-tender-area px-3">
                                <template v-if="notifications.upcoming_tender.length > 0">
                                    <h5 class="mb-2">Upcoming Expiry Tenders</h5>
                                    <div :class="{ 'scroll-tender': (notifications.upcoming_tender.length > 3) }"
                                        class="upcoming-tender px-2 pt-1 pb-1 mt-2">
                                        <a v-for="(upcomingItemDetails, upcomingIndex) in notifications.upcoming_tender"
                                            :key="upcomingIndex" class="text-decoration-none mb-2">
                                            <div class="upcoming-item">
                                                <router-link
                                                    :to="{ name: 'tender_list_upcoming', query: { id: upcomingItemDetails.serial_no } }">
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <div class="upcoming-icon-area">
                                                                <div style="background-color: rgba(3, 57, 157, 0.06)"
                                                                    class="upcoming-icon">
                                                                    <img :src="`${require('@/assets/img/icon/notification/upcoming.png')}`"
                                                                        alt="Upcoming">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-10">
                                                            <div class="upcoming-content">
                                                                <p><span>Tender Id:</span> {{
                                                                    upcomingItemDetails.serial_number
                                                                }} |
                                                                    <span>Project Name: {{
                                                                        upcomingItemDetails.project_name
                                                                    }} </span>
                                                                </p>
                                                                <p><span>Published Date:</span> {{
                                                                    $moment(upcomingItemDetails.publication_date).format('MMMM D, YYYY') }}</p>
                                                                <p><span>Closing Date:</span> {{
                                                                    $moment(upcomingItemDetails.tender_closing_date).format('MMMM D, YYYY') }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                                <template v-if="notifications.security_receival.length > 0">
                                    <h5 class="mb-2">Security Receival</h5>
                                    <div :class="{ 'scroll-tender': (notifications.security_receival.length > 3) }"
                                        class="upcoming-tender px-2 pt-1 pb-1 mt-2">
                                        <a v-for="(security_receivalDetails, securityIndex) in notifications.security_receival"
                                            :key="securityIndex" class="text-decoration-none mb-2">
                                            <div class="upcoming-item">
                                                <router-link
                                                    :to="{ name: 'tender_list_edit', params: { id: security_receivalDetails.id }, query: { notification: 1 } }">
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <div class="upcoming-icon-area">
                                                                <div style="background-color: rgba(3, 57, 157, 0.06)"
                                                                    class="upcoming-icon">
                                                                    <img :src="`${require('@/assets/img/icon/notification/upcoming.png')}`"
                                                                        alt="Upcoming">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-10">
                                                            <div class="upcoming-content">
                                                                <p><span>Serial no:</span> {{
                                                                    security_receivalDetails.serial_number
                                                                }}</p>
                                                                <p>
                                                                    <span>Project Name:</span> {{
                                                                        security_receivalDetails.project_name
                                                                    }}
                                                                </p>
                                                                <p><span> Security deposit exp date:</span> {{
                                                                    security_receivalDetails.tender_security_deposit_validity_ex_date }}</p>
                                                                <p><span>Security deposit amount:</span> {{
                                                                    security_receivalDetails.tender_security_deposit_amount }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                                <template v-if="notifications.contact_award.length > 0">
                                    <h5 class="mb-2">Contract awards</h5>
                                    <div :class="{ 'scroll-tender': (notifications.contact_award.length > 3) }"
                                        class="upcoming-tender px-2 pt-1 pb-1 mt-2">
                                        <a v-for="(contact_award, contactIndex) in notifications.contact_award"
                                            :key="contactIndex" class="text-decoration-none mb-2">
                                            <div class="upcoming-item">
                                                <router-link
                                                    :to="{ name: 'contact_award', query: { id: contact_award.tender_submission.tender_information.serial_number } }">
                                                <!-- <router-link
                                                    :to="{ name: 'list_award_and_execution', query: { id: JSON.parse(contact_award.data.item).serial_no } }"> -->
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <div class="upcoming-icon-area">
                                                                <div style="background-color: rgba(3, 57, 157, 0.06)"
                                                                    class="upcoming-icon">
                                                                    <img :src="`${require('@/assets/img/icon/notification/award.png')}`"
                                                                        alt="Upcoming">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-10">
                                                            <div class="upcoming-content">
                                                                <p><span>Tender Id:</span> {{
                                                                   contact_award.tender_submission.tender_information.serial_number }} |
                                                                    <span>Bidder Name:</span> {{
                                                                        contact_award.bidder_name }}
                                                                </p>
                                                                <p><span> Procuring Entity:</span>
                                                                    {{ contact_award.tender_submission.tender_information.project_name }}
                                                                </p>
                                                                <p v-if="contact_award.tender_submission.award_and_execution"><span>Awared date:</span>
                                                                    {{
                                                                        contact_award.tender_submission.award_and_execution.notification_of_award_date
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- /.notification-item -->
                    </div>
                    <!-- /.notification-area -->
                </div>
                <div v-if="notifications != null" class="col-md-6">
                    <div class="notification-area border  px-0 pt-3 pb-3">
                        <h4 class="px-2">Notifications </h4>
                        <hr>
                        <div class="notification-item">
                            <div class="upcoming-tender-area px-3">
                                <template v-if="notifications.performance_security.length > 0">
                                    <h5 class="mb-2 mt-2">Performance security</h5>
                                    <div :class="{ 'scroll-tender': (notifications.performance_security.length > 3) }"
                                        class="upcoming-tender px-2 pt-1 pb-1 mt-2">
                                        <a v-for="(performance_security, index) in notifications.performance_security"
                                            :key="index" class="text-decoration-none mb-2">
                                            <div class="upcoming-item">
                                                <router-link
                                                    :to="{ name: 'edit_award_and_execution', params: { id: performance_security.id }, query: { notification: 'performance_security' } }">
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <div class="upcoming-icon-area">
                                                                <div style="background-color: rgba(3, 57, 157, 0.06)"
                                                                    class="upcoming-icon">
                                                                    <img :src="`${require('@/assets/img/icon/notification/award.png')}`"
                                                                        alt="Upcoming">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-10">
                                                            <div class="upcoming-content">
                                                                <p><span>Tender Id:</span> {{ performance_security.tender_submission.tender_information.serial_number }}
                                                            </p>
                                                            <p><span> Procuring Entity:</span>
                                                                {{ performance_security.tender_submission.tender_information.project_name }}
                                                            </p>
                                                            <p><span> Performance security exp date:</span>
                                                                {{ performance_security.performance_security_exp_date }}
                                                            </p>
                                                            <p><span> Performance security amount:</span>
                                                                {{ performance_security.performance_security_amount }}
                                                            </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </a>
                                    </div>
                                    
                                </template>
                                <template v-if="notifications.bank_guarantee.length > 0">
                                    <h5 class="mb-2 mt-2">Bank guarantee</h5>
                                    <div :class="{ 'scroll-tender': (notifications.bank_guarantee.length > 3) }"
                                        class="upcoming-tender px-2 pt-1 pb-1 mt-2">
                                        <a v-for="(bank_guarantee, index) in notifications.bank_guarantee"
                                            :key="index" class="text-decoration-none mb-2">
                                            <div class="upcoming-item">
                                                <router-link
                                                    :to="{ 
                                                            name: 'edit_award_and_execution',
                                                            params: { id: JSON.parse(bank_guarantee.data.item).id },query: { notification: 'bank_guarantee' }
                                                        }">
                                                    <div class="row">
                                                        <div class="col-sm-2">
                                                            <div class="upcoming-icon-area">
                                                                <div style="background-color: rgba(3, 57, 157, 0.06)"
                                                                    class="upcoming-icon">
                                                                    <img :src="`${require('@/assets/img/icon/notification/award.png')}`"
                                                                        alt="Upcoming">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-10">
                                                                <div class="upcoming-content">
                                                                    <p><span>Tender Id:</span> {{ JSON.parse(bank_guarantee.data.item).serial_no }}
                                                                </p>
                                                                <p><span> Procuring Entity:</span>
                                                                    {{ JSON.parse(bank_guarantee.data.item).project_name }}
                                                                </p>
                                                                <p><span> Performance security exp date:</span>
                                                                    {{ JSON.parse(bank_guarantee.data.item).date }}
                                                                </p>
                                                                <p><span> Performance security amount:</span>
                                                                    {{ JSON.parse(bank_guarantee.data.item).amount }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- /.notification-item -->
                    </div>
                    <!-- /.notification-area -->
                </div>
                <div class="col-md-6">
                    <div class="chart-area px-0 pt-3 pb-3">
                        <h4 class="px-2">Monthly Tender</h4>
                        <hr>
                        <div class="chart-dropdoen px-2">
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <select v-model="graphData.month" @change="itemChange" name="" id=""
                                            class="form-control form-control-modify form-select">
                                            <option v-for="(monthItem, index) in months" :key="index" :value="monthItem.id">
                                                {{ monthItem.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <select v-model="graphData.year" @change="itemChange" name="" id=""
                                            class="form-control form-control-modify form-select">
                                            <option v-for="(itemYears, index) in years" :key="index"
                                                :value="itemYears.year">{{
                                                    itemYears.year
                                                }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isGraphLoading" class="chart">
                            <BarChart :labels="labels" :dataOne="graph.national" :dataTwo="graph.internatinal">
                            </BarChart>
                        </div>
                        <!-- spinner loading -->
                        <div v-else class="text-center mt-5 mb-5">
                            <div class="spinner-grow  text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    </div>
                    <!-- /.chart-area -->
                </div>
                <div class="col-md-6">
                    <div class="chart-area px-0 pt-3 pb-3">
                        <h4 class="px-2">Success VS Failure</h4>
                        <hr>
                        <div class="chart-dropdoen px-2">
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <select v-model="success_vs_failure_options.month" @change="successVsFailureChange"
                                            name="" id="" class="form-control form-control-modify form-select">
                                            <option v-for="(monthItem, index) in months" :key="index" :value="monthItem.id">
                                                {{ monthItem.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group mb-3">
                                        <select v-model="success_vs_failure_options.year" @change="successVsFailureChange"
                                            name="" id="" class="form-control form-control-modify form-select">
                                            <option v-for="(itemYears, index) in years" :key="index"
                                                :value="itemYears.year">{{ itemYears.year }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isSuccessVsFailureLoading" class="chart">
                            <SuccessVsFailure :labels="labels" :dataOne="successVsFailure.success"
                                :dataTwo="successVsFailure.failures"></SuccessVsFailure>
                        </div>
                        <!-- spinner loading -->
                        <div v-else class="text-center mt-5 mb-5">
                            <div class="spinner-grow  text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- /.chart-notification -->
    </div>
</template>

<script>
import IconBox from "@/components/IconBox.vue";
import BarChart from "@/components/BarChart.vue";
import SuccessVsFailure from "@/components/SuccessVsFailure.vue";
import { mapGetters, mapActions } from "vuex";
import { jsonToFormData } from "@/utility/common";
export default {
    components: {
        IconBox,
        BarChart,
        SuccessVsFailure,
    },
    computed: {
        ...mapGetters({
            concernList: "general_setting_concern/common",
            notifications: "dashboard/notifications",
            total: "dashboard/total",
            isLoading: "dashboard/isLoading",
            isGraphLoading: "dashboard/isGraphLoading",
            graph: "dashboard/graph",
            isSuccessVsFailureLoading: "dashboard/isSuccessVsFailureLoading",
            successVsFailure: "dashboard/successVsFailure",
        }),
    },
    created() {
        // for concern
        var concern_id = localStorage.getItem('concern_id') ?? 0;
        if (concern_id != 0) {
            this.query.concern_id = concern_id;
        }
        this.fetchNotifications(this.query);
        this.fetchTotal(this.query);
        this.fetchConcernList();

        this.graphData.concern_id = this.query.concern_id;
        this.fetchGraph(this.graphData);
        // concern id
        this.success_vs_failure_options.concern_id = this.query.concern_id;
        this.fetchSuccessVsFailure(this.success_vs_failure_options);


    },
    methods: {
        ...mapActions({
            fetchNotifications: "dashboard/fetchNotifications",
            fetchConcernList: "general_setting_concern/fetchCommon",
            fetchTotal: "dashboard/fetchTotal",
            fetchGraph: "dashboard/fetchGraph",
            fetchSuccessVsFailure: "dashboard/fetchSuccessVsFailure",
        }),
        itemChange() {
            this.fetchGraph(jsonToFormData(this.graphData));
        },
        successVsFailureChange() {
            this.success_vs_failure_options.concern_id = this.query.concern_id;
            this.fetchSuccessVsFailure(this.success_vs_failure_options);

        },
        changeConcern() {
            localStorage.setItem('concern_id', this.query.concern_id);
            this.fetchTotal(this.query);
            this.fetchNotifications(this.query);

            // concern id
            this.success_vs_failure_options.concern_id = this.query.concern_id;
            this.fetchSuccessVsFailure(this.success_vs_failure_options);
            // fetch graph
            this.fetchGraph(jsonToFormData(this.graphData));
        }
    },
    data() {
        return {
            icon_local_tender: `${require("@/assets/img/icon/local-tender.png")}`,
            icon_international_tend: `${require("@/assets/img/icon/international-tend.png")}`,
            icon_open_tends: `${require("@/assets/img/icon/open-tends.png")}`,
            icon_closed_tender: `${require("@/assets/img/icon/closed-tender.png")}`,
            icon_tk: `${require("@/assets/img/icon/tk.png")}`,
            icon_security_deposit: `${require("@/assets/img/icon/security-deposit.png")}`,
            icon_performance_security: `${require("@/assets/img/icon/performance-security.png")}`,
            icon_performance_security_return: `${require("@/assets/img/icon/performance-security-return.png")}`,
            icon_tender_security_return: `${require("@/assets/img/icon/tender-security-return.png")}`,
            icon_award: `${require("@/assets/img/icon/notification/award.png")}`,
            graphData: {
                month: '0',
                year: this.$moment().format('YYYY'),
            },
            success_vs_failure_options: {
                month: '0',
                year: this.$moment().format('YYYY'),
            },
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            months: [
                { 'id': "0", 'name': "All" },
                { 'id': "01", "name": "January" },
                { 'id': "02", "name": "February" },
                { 'id': "03", "name": "March" },
                { 'id': "04", "name": "April" },
                { 'id': "05", "name": "May" },
                { 'id': "06", "name": "June" },
                { 'id': "07", "name": "July" },
                { 'id': "08", "name": "August" },
                { 'id': "09", "name": "September" },
                { 'id': "10", "name": "October" },
                { 'id': "11", "name": "November" },
                { 'id': "12", "name": "December" },
            ],
            years: [
                { 'year': '2017' },
                { 'year': '2018' },
                { 'year': '2019' },
                { 'year': '2020' },
                { 'year': '2021' },
                { 'year': '2022' },
                { 'year': '2023' },
                { 'year': '2024' },
                { 'year': '2025' }
            ],
            query: {
                concern_id: 0
            }
        };
    },
};
</script>

<style lang="scss" scoped>
.upcoming-icon-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .upcoming-icon {
        padding: 16px;
        width: 60px;
        height: 60px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: none;
        }
    }
}

.upcoming-item {
    box-shadow: 0px 4px 10px rgba(204, 204, 204, 0.15);
    background: $white_color;
    border: 1px solid transparent;
    padding: 10px 0px;
}

a {
    display: block;
    color: black;

    &:hover {
        color: black;

        .upcoming-item {
            border: 1px solid rgba(69, 100, 129, 0.22);
        }
    }
}

.upcoming-content {
    font-family: $ff_Inter;

    p {
        color: rgba(0, 0, 0, 0.5);
    }

    span {
        font-weight: 700;
        color: #2d3748;
    }
}

.scroll-tender {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 278px;
}

@media (min-width: 768px) and (max-width: 1365px) {
    .upcoming-item {
        padding: 10px 20px;

        .row {
            .col-sm-2 {
                width: 100%;

                .upcoming-icon-area {
                    margin-bottom: 25px;
                }
            }

            .col-sm-10 {
                width: 100%;
            }
        }
    }
}</style>
